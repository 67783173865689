






















































































import Api from "@/includes/logic/Api";
import { ReferralsRawData } from "@/views/chat/statistics/logic/usersStatTypes";
import ReferralsTable from "@/views/chat/statistics/components/referrals/referralsTable.vue";
import ReferralsLogins from "./components/referrals/referralsLogins.vue";
import ReferralsLocales from "./components/referrals/referralsLocales.vue";
import TopReferrals from "@/views/chat/statistics/components/referrals/topReferrals.vue";
import { errorNotification } from '@/includes/NotificationService'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  computed: {
    SitePermissionEnum() {
      return SitePermissionEnum
    }
  },
  components: {
    PermissionsBanner,
    TopReferrals,
    ReferralsLocales,
    ReferralsLogins,
    PageTitle,
    TableExportButtons,
    ReferralsTable,
    NotAvailableOptionsOverlay
  },
  methods: {
    moment
  },
  data() {
    return {
      EntityTypes
    }
  }
})
export default class StatisticsReferrals extends Mixins<TariffsTagsHelper>(TariffsTagsHelper) {

  rawData: ReferralsRawData | null = null

  loaded: boolean = false

  referrals: any = null

  @Watch('$route.params.CHAT_ID')
  onChatChange() {
    if (!this.getTagsByFieldKey('stat_referrals')) {
      this.getStat()
    }
  }

  get needRequest() {
    return !this.getTagsByFieldKey('stat_referrals') && !this.$store.getters.botNotAdmin
  }

  getStat(): void {
    if (this.needRequest) {
      const statAccessKey = this.$store.state.chatState.chat?.stat_access_key
      this.loaded = true

      if (statAccessKey) {
        Api.getChatReferralsStat(statAccessKey).then(res => {
          // this.rawData = require('./data.json')
          this.rawData = res.data
          this.referrals = this.rawData!.users.sort((a, b) => b.referrals - a.referrals)
        }).catch((error) => {
          errorNotification(error.response ?? error.message)
        })
          .finally(() => {
            this.loaded = false
          })
      } else {
        this.loaded = false
      }
    }
  }

  mounted() {
    if (this.$store.getters.canStatistics) {
      this.getStat()
    }
  }
}
